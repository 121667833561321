<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  html, body {
    margin: 0;
    padding: 0;
    /*取消移动端高亮*/
    -webkit-tap-highlight-color:transparent;
  }
  html,body,#app{
    height: 100%;
    background: #F0F2F5;
  }
  .box {
    border-radius: 5px;
    margin-top: 10px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #E4E7ED;
    padding: 0 5px;
  }
  .box-left {
    padding-right: 5px;
    width: 50%;
    float: left;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .box-right {
    padding-left: 5px;
    width: 50%;
    float: right;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .charts-canvas {
    height: 400px;
    border-radius: 5px;
    padding: 5px;
  }
  .box-row {
    border-radius: 5px;
    overflow:hidden;
  }
  .bg-white {
    background-color: white;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-left: none!important;
  }
  .el-drawer__open > .el-drawer.rtl {
    overflow: auto!important;
  }

  .bi-channel-date-search {
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #E4E7ED;
  }
  .el-form-item {
    margin-bottom: 0;
  }
</style>
